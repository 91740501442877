import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import {
  Container,
  Overlay,
  ModalWrapper,
  ModalContainer,
  ModalHeadline,
  ModalText,
  Input,
  H1,
  ButtonContainer,
  Button,
  FalscheAntwort,
  CodeInfo,
} from "../components/Design";

export default function Wochen({ data }) {
  const woche = data.allSitePage.edges[0].node.context;

  const [modal, setModal] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [falseAnswer, setFalseAnswer] = React.useState(false);
  const [code, setCode] = React.useState(false);
  const [random, setRandom] = React.useState(0);

  const checkAnswer = () => {
    let antworten = woche.antworten;

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    }

    for (let index = 0; index < antworten.length; index++) {
      if (antworten[index].toLowerCase().replace(/\s/g, "") === input.toLowerCase().replace(/\s/g, "")) {
        setCode(true);
        break;
      } else {
        setRandom(getRandomInt(0, 4));
        setFalseAnswer(true);
        setInput("");
      }
    }
  };

  const closeModal = () => {
    setModal(false);
    setShowModal(false);
  };

  const randomText = ["Das ist leider falsch!", "Nein!", "Falsche Antwort!", "Leider ist das falsch!"];

  return (
    <Container>
      <Seo title={`Woche ${woche.slug}`} article />
      {code ? (
        <div>
          <H1>
            Code {woche.slug}: {woche.code}
          </H1>
          <CodeInfo>
            {woche.slug < 52 ? (
              <>Trage diesen Code in deine Übersicht ein. Du wirst ihn in weiteren Rätseln eventuell noch benötigen.</>
            ) : (
              <>
                Du hast alle Rätsel des Kalenders gelöst. Trage nun deine gesammelten Punkte ganz hinten in deine
                Übersicht ein.
              </>
            )}
          </CodeInfo>
          {woche.slug < 52 && (
            <Link to={`/${parseInt(woche.slug) + 1}`}>
              <Button>Nächste Woche</Button>
            </Link>
          )}
        </div>
      ) : (
        <div>
          <H1>{woche.title}</H1>
          {modal !== false && (
            <ModalWrapper>
              {showModal ? (
                <ModalContainer>
                  <ModalHeadline>{modal.title}</ModalHeadline>
                  <ModalText dangerouslySetInnerHTML={{ __html: modal.content }} />
                  <Button onClick={() => closeModal(false)}>Schließen</Button>
                </ModalContainer>
              ) : (
                <ModalContainer>
                  <ModalHeadline>{modal.title}</ModalHeadline>
                  <ModalText>Willst du dir das wirklich anschauen?</ModalText>
                  <ButtonContainer>
                    <Button onClick={() => setShowModal(true)} style={{ marginRight: "5px" }}>
                      Ja
                    </Button>
                    <Button onClick={() => closeModal(false)} style={{ marginLeft: "5px" }}>
                      Nein
                    </Button>
                  </ButtonContainer>
                </ModalContainer>
              )}
              <Overlay onClick={() => closeModal(false)} />
            </ModalWrapper>
          )}
          <FalscheAntwort>{falseAnswer && randomText[random]}</FalscheAntwort>
          <Input placeholder="Antwort" value={input} onChange={(e) => setInput(e.target.value)} />
          <Button onClick={() => checkAnswer()} important>
            Prüfen
          </Button>
          <Button onClick={() => setModal({ title: "Hinweis Nr. 1", content: woche.hinweis_1 })} secondary>
            1. Hinweis
          </Button>
          <Button onClick={() => setModal({ title: "Hinweis Nr. 2", content: woche.hinweis_2 })} secondary>
            2. Hinweis
          </Button>
          <Button onClick={() => setModal({ title: "Auflösung", content: woche.aufloesung })} secondary>
            Auflösung
          </Button>
        </div>
      )}
    </Container>
  );
}

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            title
            slug
            hinweis_1
            hinweis_2
            aufloesung
            antworten
            code
          }
        }
      }
    }
  }
`;
